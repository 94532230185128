export var supplyMaterialTypeData = [
  { label: '主板', value: '主板' },
  { label: 'CPU', value: 'CPU' },
  { label: '硬盘', value: '硬盘' },
  { label: '内存', value: '内存' },
  { label: '板卡', value: '板卡' },
  { label: '电源', value: '电源' },
  { label: '风扇', value: '风扇' },
  { label: '散热器', value: '散热器' },
  { label: '线缆', value: '线缆' },
  { label: '交换机', value: '交换机' },
  { label: '磁盘阵列', value: '磁盘阵列' },
  { label: 'OEM安全产品', value: 'OEM安全产品' },
  { label: '商品', value: '商品' },
  { label: '包材', value: '包材' },
  { label: '结构件', value: '结构件' },
  { label: '光模块', value: '光模块' },
  { label: '软件', value: '软件' },
  { label: '固定资产', value: '固定资产' },
  { label: '外设', value: '外设' },
  { label: '云计算集成外包', value: '云计算集成外包' },
  { label: '外协', value: '外协' },
  { label: '元器件', value: '元器件' },
  { label: '其它', value: '其它' }
]
export var SupplierRegistrFormData = {
  code: '',
  name: '',
  type: null,
  creditCode: '',
  bankCode: '',
  openingBank: '',
  status: 3,
  businessAddress: '',
  businessScope: '',
  supplyMaterialType: '',
  vmi: '',
  supplierAddressList: [{
    country: '',
    addressProperty: null,
    addressName: '',
    province: '',
    city: '',
    area: '',
    detailedAddress: ''
  }
  ],
  supplierContactList: [
    {
      name: '',
      phone: '',
      deptCode: '',
      mail: ''
    }
  ],
  fileVoList: [
    {
      name: '',
      srmFileList: [
      ]
    }
  ]
}

export var supplierAddressList = {
  country: '',
  address_property: '',
  addressName: '',
  province: '',
  city: '',
  area: '',
  detailedAddress: ''
}

export var supplierContactList = {
  name: '',
  phone: '',
  deptCode: '',
  email: ''
}

export var srmFileList = {
  name: '',
  srmFileList: [
  ]
}

export var SupplierRegistrFormAttr = {
  id: { readonly: false, hidden: true, required: true },
  code: { readonly: false, hidden: true, required: true },
  name: { readonly: false, hidden: false, required: true },
  type: { readonly: false, hidden: false, required: true },
  creditCode: { readonly: false, hidden: false, required: true },
  bankCode: { readonly: false, hidden: false, required: true },
  openingBank: { readonly: false, hidden: false, required: true },
  status: { readonly: false, hidden: false, required: true },
  businessAddress: { readonly: false, hidden: false, required: true },
  businessScope: { readonly: false, hidden: false, required: true },
  supplyMaterialType: { readonly: false, hidden: false, required: true },
  vmi: { readonly: false, hidden: false, required: true },
  buyerName: { readonly: true, hidden: false, required: true },
  statement: { readonly: true, hidden: false, required: true },
  supplierAddressList: {
    readonly: false,
    hidden: false,
    required: false,
    country: { readonly: false, hidden: false, required: true },
    address_property: { readonly: false, hidden: false, required: true },
    addressName: { readonly: false, hidden: false, required: true },
    address: { readonly: false, hidden: false, required: true },
    province: { readonly: false, hidden: false, required: true },
    city: { readonly: false, hidden: false, required: true },
    area: { readonly: false, hidden: false, required: true },
    detailedAddress: { readonly: false, hidden: false, required: true }
  },
  supplierContactList:
      {
        readonly: false,
        hidden: false,
        required: false,
        name: { readonly: false, hidden: false, required: true },
        phone: { readonly: false, hidden: false, required: true },
        deptCode: { readonly: false, hidden: false, required: true },
        email: { readonly: false, hidden: false, required: true }
      },
  srmFileList:
      {
        readonly: false,
        hidden: false,
        required: false,
        name: { readonly: false, hidden: false, required: true },
        srmFileList:
          {
            readonly: false,
            hidden: false,
            required: false,
            createDate: { readonly: false, hidden: false, required: false },
            createUserId: { readonly: false, hidden: false, required: false },
            url: { readonly: false, hidden: false, required: false },
            name: { readonly: false, hidden: false, required: false },
            fileSize: { readonly: false, hidden: false, required: false },
            groupId: { readonly: false, hidden: false, required: false },
            id: { readonly: false, hidden: false, required: false },
            modifyDate: { readonly: false, hidden: false, required: false }
          }
      }

}

export var SupplierListSearchList = [
  { labe: '供应商名称', code: 'name', type: 'input' },
  { labe: '供应商编码', code: 'erpCode', type: 'input' },
  {
    labe: '供应物料',
    code: 'supplyMaterialType',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '主板', value: '主板' },
      { label: 'CPU', value: 'CPU' },
      { label: '硬盘', value: '硬盘' },
      { label: '内存', value: '内存' },
      { label: '板卡', value: '板卡' },
      { label: '电源', value: '电源' },
      { label: '风扇', value: '风扇' },
      { label: '散热器', value: '散热器' },
      { label: '线缆', value: '线缆' },
      { label: '交换机', value: '交换机' },
      { label: '磁盘阵列', value: '磁盘阵列' },
      { label: 'OEM安全产品', value: 'OEM安全产品' },
      { label: '商品', value: '商品' },
      { label: '包材', value: '包材' },
      { label: '结构件', value: '结构件' },
      { label: '光模块', value: '光模块' },
      { label: '软件', value: '软件' },
      { label: '固定资产', value: '固定资产' },
      { label: '外设', value: '外设' },
      { label: '云计算集成外包', value: '云计算集成外包' },
      { label: '外协', value: '外协' },
      { label: '元器件', value: '元器件' },
      { label: '其它', value: '其它' }
    ]
  },
  {
    labe: '供应商状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '启用', value: '3' },
      { label: '冻结', value: '2' },
      { label: '淘汰', value: '1' }
    ]
  },
  {
    labe: '供应商级别',
    code: 'type',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '合格', value: '3' },
      { label: '一般', value: '2' },
      { label: '一次', value: '1' }
    ]
  }
]
export var SupplierListSearchData = {
  name: '',
  erpCode: '',
  supplyMaterialType: '',
  status: '',
  type: ''
}

export var SupplierRegistrFormShow = {
  id: { readonly: false, hidden: true, required: false },
  code: { readonly: true, hidden: false, required: false },
  name: { readonly: true, hidden: true, required: false },
  type: { readonly: true, hidden: false, required: false },
  creditCode: { readonly: true, hidden: false, required: false },
  bankCode: { readonly: true, hidden: false, required: false },
  openingBank: { readonly: true, hidden: false, required: false },
  status: { readonly: true, hidden: false, required: false },
  businessAddress: { readonly: true, hidden: false, required: false },
  businessScope: { readonly: true, hidden: false, required: false },
  supplyMaterialType: { readonly: true, hidden: false, required: false },
  vmi: { readonly: true, hidden: false, required: false },
  buyerName: { readonly: true, hidden: false, required: true },
  statement: { readonly: true, hidden: false, required: true },
  supplierAddressList: {
    readonly: true,
    hidden: false,
    required: false,
    country: { readonly: true, hidden: false, required: false },
    address_property: { readonly: true, hidden: false, required: false },
    addressName: { readonly: true, hidden: true, required: false },
    address: { readonly: true, hidden: false, required: false },
    province: { readonly: true, hidden: false, required: false },
    city: { readonly: true, hidden: false, required: false },
    area: { readonly: true, hidden: false, required: false },
    detailedAddress: { readonly: true, hidden: true, required: false }
  },
  supplierContactList:
        {
          readonly: true,
          hidden: false,
          required: false,
          name: { readonly: true, hidden: false, required: false },
          phone: { readonly: true, hidden: false, required: false },
          deptCode: { readonly: true, hidden: false, required: false },
          email: { readonly: true, hidden: false, required: false }
        },
  srmFileList:
        {
          readonly: true,
          hidden: false,
          required: false,
          name: { readonly: true, hidden: false, required: false },
          srmFileList:
            {
              readonly: true,
              hidden: false,
              required: false,
              createDate: { readonly: false, hidden: false, required: false },
              createUserId: { readonly: false, hidden: false, required: false },
              url: { readonly: false, hidden: false, required: false },
              name: { readonly: false, hidden: false, required: false },
              fileSize: { readonly: false, hidden: false, required: false },
              groupId: { readonly: false, hidden: false, required: false },
              id: { readonly: false, hidden: false, required: false },
              modifyDate: { readonly: false, hidden: false, required: false }
            }
        }

}

export var SupplierRegistrFormEdit = {
  id: { readonly: false, hidden: true, required: false },
  code: { readonly: true, hidden: false, required: false },
  name: { readonly: true, hidden: true, required: false },
  type: { readonly: false, hidden: false, required: false },
  creditCode: { readonly: true, hidden: false, required: false },
  bankCode: { readonly: true, hidden: false, required: false },
  openingBank: { readonly: true, hidden: false, required: false },
  status: { readonly: false, hidden: false, required: false },
  businessAddress: { readonly: true, hidden: false, required: false },
  businessScope: { readonly: false, hidden: false, required: false },
  supplyMaterialType: { readonly: false, hidden: false, required: false },
  vmi: { readonly: false, hidden: false, required: false },
  buyerName: { readonly: true, hidden: false, required: true },
  statement: { readonly: true, hidden: false, required: true },
  supplierAddressList: {
    readonly: true,
    hidden: false,
    required: false,
    country: { readonly: true, hidden: false, required: false },
    address_property: { readonly: true, hidden: false, required: false },
    addressName: { readonly: true, hidden: false, required: false },
    address: { readonly: true, hidden: false, required: false },
    province: { readonly: true, hidden: false, required: false },
    city: { readonly: true, hidden: false, required: false },
    area: { readonly: true, hidden: false, required: false },
    detailedAddress: { readonly: true, hidden: false, required: false }
  },
  supplierContactList:
          {
            readonly: true,
            hidden: false,
            required: false,
            name: { readonly: true, hidden: false, required: false },
            phone: { readonly: true, hidden: false, required: false },
            deptCode: { readonly: true, hidden: false, required: false },
            email: { readonly: true, hidden: false, required: false }
          },
  srmFileList:
          {
            readonly: false,
            hidden: false,
            required: false,
            name: { readonly: false, hidden: false, required: false },
            srmFileList:
              {
                readonly: false,
                hidden: false,
                required: false,
                createDate: { readonly: false, hidden: false, required: false },
                createUserId: { readonly: false, hidden: false, required: false },
                url: { readonly: false, hidden: false, required: false },
                name: { readonly: false, hidden: false, required: false },
                fileSize: { readonly: false, hidden: false, required: false },
                groupId: { readonly: false, hidden: false, required: false },
                id: { readonly: false, hidden: false, required: false },
                modifyDate: { readonly: false, hidden: false, required: false }
              }
          }

}
