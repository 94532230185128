<template>
  <div class="mainform">
    <div class="infomation" v-if="haveInfo">您好，xxxx公司信息审批未通过</div>
    <div class="mainHeader">
      {{formName}}
      <span v-if="canback" @click="back" class="back">返回></span>
    </div>
    <div class="form">
      <div class="header">基本信息</div>
      <div class="main">
        <div class="col col4" v-show="!FormAttr.code.hidden">
          <div class="title">
            <span v-if="!FormAttr.code.readonly" class="priority">*</span>供应商编号
          </div>
          <div class="input" :class="{ inputReadonly: FormAttr.code.readonly }">
            <input :readonly="FormAttr.code.readonly" v-model="formData.erpCode" :required="FormAttr.code.required" placeholder="系统自动生成">
          </div>
        </div>
        <div class="col col4" v-show="!FormAttr.name.hidden">
          <div class="title"><span v-if="!FormAttr.name.readonly" class="priority">*</span>供应商名称</div>
          <div class="input" :class="{ inputReadonly: FormAttr.name.readonly }">
            <input :readonly="FormAttr.name.readonly"  v-model="formData.name" :required="FormAttr.name.required" placeholder="请输入供应商名称">
          </div>
        </div>
        <div class="col col4"  v-show="!FormAttr.openingBank.hidden">
          <div class="title"><span v-if="!FormAttr.openingBank.readonly" class="priority">*</span>开户行名称</div>
          <div class="input" :class="{ inputReadonly: FormAttr.openingBank.readonly }">
            <input :readonly="FormAttr.openingBank.readonly"  v-model="formData.openingBank" :required="FormAttr.openingBank.required" placeholder="请输入开户行名称">
          </div>
        </div>
        <div class="col col4" v-show="!FormAttr.creditCode.hidden">
          <div class="title"><span  v-if="!FormAttr.creditCode.readonly" class="priority">*</span>统一信用代码</div>
          <div class="input" :class="{ inputReadonly: FormAttr.creditCode.readonly }">
            <input :readonly="FormAttr.creditCode.readonly"  v-model="formData.creditCode" @blur="verifySocialCode(formData.creditCode,formData,'creditCode')" :required="FormAttr.creditCode.required" placeholder="请输入统一信用代码">
          </div>
        </div>
        <div class="col col4" v-show="!FormAttr.bankCode.hidden">
          <div class="title"><span v-if="!FormAttr.bankCode.readonly" class="priority">*</span>银行账号</div>
          <div class="input" :class="{ inputReadonly: FormAttr.bankCode.readonly }">
            <input :readonly="FormAttr.bankCode.readonly" v-model="formData.bankCode" @blur="verifyBankCard(formData.bankCode,formData,'bankCode')" :required="FormAttr.bankCode.required" placeholder="请输入银行账号">
          </div>
        </div>
        <div class="col col4" v-show="!FormAttr.businessAddress.hidden">
          <div class="title"><span v-if="!FormAttr.businessAddress.readonly" class="priority">*</span>公司营业地址</div>
          <div class="input" :class="{ inputReadonly: FormAttr.businessAddress.readonly }">
            <input :readonly="FormAttr.businessAddress.readonly" v-model="formData.businessAddress" :required="FormAttr.businessAddress.required" placeholder="公司营业地址">
          </div>
        </div>
        <!-- <div class="col col4" v-if="!FormAttr.businessScope.hidden">
          <div class="title"><span v-if="!FormAttr.businessScope.readonly" class="priority">*</span>经营范围</div>
          <div class="input" :class="{ inputReadonly: FormAttr.businessScope.readonly }">
            <el-select filterable multiple :disabled="FormAttr.businessScope.readonly" v-model="formData.businessScopeList" :required="FormAttr.businessScope.required" placeholder="请选择">
              <el-option
                  v-for="item in businessScopeData"
                  :key="item.value"
                  :label="item.companyDesc"
                  :value="item.businessScope">
              </el-option>
            </el-select>
          </div>
        </div> -->
        <div class="col col4" v-if="!FormAttr.supplyMaterialType.hidden">
          <div class="title"><span  v-if="!FormAttr.supplyMaterialType.readonly" class="priority">*</span>供应物料类别</div>
          <div class="input" :class="{ inputReadonly: FormAttr.supplyMaterialType.readonly }">
            <el-select filterable multiple :disabled="FormAttr.supplyMaterialType.readonly" v-model="formData.supplyMaterialTypeList" :required="FormAttr.supplyMaterialType.required" placeholder="请选择">
              <el-option
                  v-for="item in supplyMaterialTypeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.type.hidden">
          <div class="title"><span v-if="!FormAttr.type.readonly" class="priority">*</span>供应商级别</div>
          <div class="input input-radio" v-if="!FormAttr.type.readonly">
            <span class="radio"><input :disabled="FormAttr.type.readonly" :required="FormAttr.type.required" v-model="formData.type" name="type" type="radio" value="1">一次</span>
            <span class="radio"><input :disabled="FormAttr.type.readonly" :required="FormAttr.type.required" v-model="formData.type" name="type" type="radio" value="2">一般</span>
            <span class="radio"><input :disabled="FormAttr.type.readonly" :required="FormAttr.type.required" v-model="formData.type" name="type" type="radio" value="3">合格</span>
          </div>
          <div class="input inputReadonly" v-if="FormAttr.type.readonly">
            <input :readonly="true" :value='formData.type=="1"?"一次":formData.type=="2"?"一般":"合格"'>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.vmi.hidden">
          <div class="title"><span v-if="!FormAttr.vmi.readonly" class="priority">*</span>VMI</div>
          <div class="input input-radio" v-if="!FormAttr.vmi.readonly">
            <span class="radio"><input :disabled="FormAttr.vmi.readonly" :required="FormAttr.vmi.required" v-model="formData.vmi" name="vmi" type="radio" value=1>是</span>
            <span class="radio"><input :disabled="FormAttr.vmi.readonly" :required="FormAttr.vmi.required" v-model="formData.vmi" name="vmi" type="radio" value=2>否</span>
          </div>
          <div class="input inputReadonly" v-if="FormAttr.vmi.readonly">
            <input :readonly="true" :value='formData.vmi=="1"?"是":formData.vmi=="2"?"否":""'>
          </div>
        </div>
        <!-- <div class="col col4" v-if="!FormAttr.status.hidden">
          <div class="title"><span v-if="!FormAttr.status.readonly" class="priority">*</span>状态</div>
          <div class="input input-radio" v-if="!FormAttr.status.readonly">
            <span class="radio"><input :disabled="FormAttr.status.readonly" :required="FormAttr.status.required" v-model="formData.status" name="status" type="radio" value=3>启用</span>
            <span class="radio"><input :disabled="FormAttr.status.readonly" :required="FormAttr.status.required" v-model="formData.status" name="status" type="radio" value=1>淘汰</span>
            <span class="radio"><input :disabled="FormAttr.status.readonly" :required="FormAttr.status.required" v-model="formData.status" name="status" type="radio" value=2>冻结</span>
          </div>
          <div class="input inputReadonly" v-if="FormAttr.status.readonly">
            <input :readonly="true" :value='formData.status=="1"?"淘汰":formData.status=="2"?"冻结":"启用"'>
          </div>
        </div> -->
        <div class="col col4" v-if="!FormAttr.buyerName.hidden">
          <div class="title"><span  v-if="!FormAttr.buyerName.readonly" class="priority">*</span>采购员</div>
          <div class="input" :class="{ inputReadonly: FormAttr.buyerName.readonly }">
            <el-select filterable :disabled="FormAttr.buyerName.readonly" v-model="formData.buyerCode" :required="FormAttr.buyerName.required" placeholder="请选择采购员">
              <el-option
                  v-for="item in participantOptions"
                  :key="item.username"
                  :label="item.name"
                  :value="item.username">
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- <div class="col col4"  v-if="!FormAttr.statement.hidden">
          <div class="title"><span v-if="!FormAttr.statement.readonly" class="priority">*</span>对账单</div>
          <div class="input input-radio" :class="{ inputReadonly: FormAttr.statement.readonly }">
            <span class="radio"><input :disabled="FormAttr.statement.readonly" :required="FormAttr.statement.required" v-model="formData.statement" name="statement" type="radio" value="系统创建">系统创建</span>
            <span class="radio"><input :disabled="FormAttr.statement.readonly" :required="FormAttr.statement.required" v-model="formData.statement" name="statement" type="radio" value="手动创建">手动创建</span>
          </div>
        </div> -->
      </div>
      <div class="header">地址</div>
      <div class="otherTable" v-show="!FormAttr.supplierAddressList.hidden">
        <div class="mainBody" :key="key" v-for="(item,key) in formData.supplierAddressList" >
          <div class="card">
            <div class="col col4" v-show="!FormAttr.supplierAddressList.address_property.hidden">
              <div class="title"><span v-if="!FormAttr.supplierAddressList.address_property.readonly" class="priority">*</span>地址属性</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierAddressList.address_property.readonly }">
                <el-select filterable :disabled="FormAttr.supplierAddressList.address_property.readonly"
                 :required="FormAttr.supplierAddressList.address_property.required"
                 v-model="formData.supplierAddressList[key].addressProperty" placeholder="请选择">
                  <el-option label="返修地址" value=1></el-option>
                  <el-option label="仓库地址" value=2></el-option>
                  <el-option label="其他地址" value=3></el-option>
                </el-select>
              </div>
            </div>
            <div class="col col4" v-if="FormAttr.supplierAddressList.addressName.readonly">
              <div class="title">地址名称</div>
              <div class="input inputReadonly">
                <input :readonly="true" :value="formData.supplierAddressList[key].addressName" >
              </div>
            </div>
            <div class="col col8 col_des"  v-show="!FormAttr.supplierAddressList.addressName.hidden">
              <div class="title"><span v-if="!FormAttr.supplierAddressList.addressName.readonly" class="priority">*</span>地址名称</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierAddressList.addressName.readonly }">
                <input :readonly="FormAttr.supplierAddressList.addressName.readonly"
                :required="FormAttr.supplierAddressList.addressName.required"
                v-model="formData.supplierAddressList[key].addressName" placeholder="地址名称">
              </div>
              <div class="label" v-if="!FormAttr.supplierAddressList.addressName.readonly">地址名称用于区分同类型地址，例如：北京返修地址、天津返修地址</div>
            </div>
            <div class="col col8" v-show="!FormAttr.supplierAddressList.address.hidden">
              <div class="title">
                <span v-if="!FormAttr.supplierAddressList.address.readonly" class="priority">*</span>
                <span v-if="!FormAttr.supplierAddressList.address.readonly">选择地址</span>
                <span v-if="FormAttr.supplierAddressList.address.readonly">地址详情</span>
              </div>
              <area-selector :readonly="FormAttr.supplierAddressList.address.readonly" v-if="!FormAttr.supplierAddressList.address.readonly"
                  :required="FormAttr.supplierAddressList.address.required"
                  :countrydData = "formData.supplierAddressList[key].country"
                  :provincedData = "formData.supplierAddressList[key].province"
                  :citydData = "formData.supplierAddressList[key].city"
                  :districtdData = "formData.supplierAddressList[key].area"
                 @changAction="changAction" :addrIndex="key"></area-selector>
                 <div class="input inputReadonly" v-if="FormAttr.supplierAddressList.address.readonly">
                    <input :readonly="true" :value='formData.supplierAddressList[key].country+
                    formData.supplierAddressList[key].province+
                    formData.supplierAddressList[key].city+
                    formData.supplierAddressList[key].area+
                    formData.supplierAddressList[key].detailedAddress'>
                </div>
            </div>
            <div class="col col8" v-show="!FormAttr.supplierAddressList.detailedAddress.hidden">
              <div class="title"></div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierAddressList.detailedAddress.readonly }">
                <input :readonly="FormAttr.supplierAddressList.detailedAddress.readonly"
                :required="FormAttr.supplierAddressList.detailedAddress.required"
                v-model="formData.supplierAddressList[key].detailedAddress" placeholder="请输入详细地址">
              </div>
            </div>
          </div>
          <div class="menu" v-if="!FormAttr.supplierAddressList.readonly">
            <div v-if="formData.supplierAddressList.length>1">
              <el-button type="primary" plain icon="el-icon-minus" @click="dellist(formData.supplierAddressList,key)" circle size="mini"></el-button>
            </div>
            <div v-if="formData.supplierAddressList.length-1==key">
              <el-button type="primary" plain icon="el-icon-plus" @click="addlist(formData.supplierAddressList,supplierAddressList)" circle size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header">联系人</div>
      <div class="otherTable" v-show="!FormAttr.supplierContactList.hidden">
        <div class="mainBody" :key="key" v-for="(item,key) in formData.supplierContactList">
          <div class="card">
            <div class="col col4" v-show="!FormAttr.supplierContactList.name.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.name.readonly" class="priority">*</span>联系人姓名</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.name.readonly }">
                <input :readonly="FormAttr.supplierContactList.name.readonly"
                :required="FormAttr.supplierContactList.name.required"
                 v-model="formData.supplierContactList[key].name" placeholder="联系人姓名">
              </div>
            </div>
            <div class="col col4" v-show="!FormAttr.supplierContactList.phone.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.phone.readonly" class="priority">*</span>电话</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.phone.readonly }">
                <input :readonly="FormAttr.supplierContactList.phone.readonly"
                :required="FormAttr.supplierContactList.phone.required"
                 @blur="verifyPhone(formData.supplierContactList[key].phone,formData.supplierContactList[key],'phone')"
                 v-model="formData.supplierContactList[key].phone" placeholder="电话">
              </div>
            </div>
            <div class="col col4" v-show="!FormAttr.supplierContactList.deptCode.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.deptCode.readonly" class="priority">*</span>部门</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.deptCode.readonly }">
                 <el-select :disabled="FormAttr.supplierContactList.deptCode.readonly"
                    :required="FormAttr.supplierContactList.deptCode.required"
                    v-model="formData.supplierContactList[key].deptCode"
                    placeholder="请选择部门">
                    <el-option label="销售部" value="销售部"></el-option>
                    <el-option label="采购部" value="采购部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="品质部" value="品质部"></el-option>
                    <el-option label="产品部" value="产品部"></el-option>
                </el-select>
              </div>
            </div>
            <div class="col col4" v-show="!FormAttr.supplierContactList.email.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.email.readonly" class="priority">*</span>邮箱</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.email.readonly }">
                <input :readonly="FormAttr.supplierContactList.email.readonly"
                :required="FormAttr.supplierContactList.email.required"
                 v-model="formData.supplierContactList[key].mail"
                 @blur="verifyEmail(formData.supplierContactList[key].mail,formData.supplierContactList[key],'mail')"
                 placeholder="邮箱">
              </div>
            </div>
          </div>
          <div class="menu" v-if="!FormAttr.supplierContactList.readonly">
            <div v-if="formData.supplierContactList.length>1">
              <el-button type="primary" plain icon="el-icon-minus" @click="dellist(formData.supplierContactList,key)" circle size="mini"></el-button>
            </div>
            <div v-if="formData.supplierContactList.length-1==key">
              <el-button type="primary" plain icon="el-icon-plus" @click="addlist(formData.supplierContactList,supplierContactList)" circle size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header">认证文件</div>
      <div class="otherTable" v-show="!FormAttr.srmFileList.hidden">
        <div class="mainBody" :key="key" v-for="(item,key) in formData.fileVoList">
          <div class="card">
            <div class="col col8 col_des" v-show="!FormAttr.srmFileList.name.hidden">
              <div class="title"><span v-if="!FormAttr.srmFileList.name.readonly" class="priority">*</span>文件类型</div>
              <div class="input" :class="{ inputReadonly: FormAttr.srmFileList.name.readonly }">
                <el-select filterable :disabled="FormAttr.srmFileList.name.readonly"
                 :required="FormAttr.srmFileList.name.required"
                 v-model="formData.fileVoList[key].name" placeholder="请选择">
                  <el-option label="营业执照" value="营业执照"></el-option>
                  <el-option label="品质认证文件" value="品质认证文件"></el-option>
                  <el-option label="其他文件" value="其他文件"></el-option>
                </el-select>
              </div>
            </div>
            <div class="col col8" v-show="!FormAttr.srmFileList.srmFileList.hidden">
              <div class="title"><span v-if="!FormAttr.srmFileList.srmFileList.readonly" class="priority">*</span>上传文件</div>
              <el-upload
                  :disabled="FormAttr.srmFileList.srmFileList.readonly"
                  :required="FormAttr.srmFileList.srmFileList.required"
                  :class="{ uploadReadonly: FormAttr.srmFileList.srmFileList.readonly }"
                  @click="clickKey(key)"
                  :action="actionURL"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :headers="tokenHeader"
                  :on-success="handleSuccess"
                  multiple
                  :limit="3"
                  :on-exceed="handleExceed"
                  :file-list="formData.fileVoList[key].srmFileList"
                >
                  <el-button v-if="!FormAttr.srmFileList.srmFileList.readonly" size="small" type="primary" plain>点击上传</el-button>
                  <template #tip>
                    <span v-if="!FormAttr.srmFileList.srmFileList.readonly" class="el-upload__tip" style="margin-left:10px">只能上传 jpg/png 文件，且不超过 500kb</span>
                  </template>
                </el-upload>
            </div>
          </div>
          <div class="menu" v-if="!FormAttr.srmFileList.readonly">
            <div v-if="formData.fileVoList.length>1">
              <el-button type="primary" plain icon="el-icon-minus" @click="dellist(formData.fileVoList,key)" circle size="mini"></el-button>
            </div>
            <div v-if="formData.fileVoList.length-1==key">
              <el-button type="primary" plain icon="el-icon-plus" @click="addlist(formData.fileVoList,srmFileList)" circle size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <el-button v-if="canChange" @click="change" class="change" type="primary" size="medium">修改</el-button>
      <el-button v-if="canSave" @click="save" class="save" type="primary" size="medium">保存</el-button>
      <el-button v-if="canSubmit" @click="submit" class="submit" type="primary" size="medium">提交</el-button>
      <el-button v-if="canReject" @click="reject" class="reject" type="primary" size="medium">驳回</el-button>
    </div>
    <div class="Approval">
      <input name="cgysp">
      <input name="bjfzrsp">
      <input name="cpyzlbhq">
      <input name="cgbjlsp">
    </div>
  </div>
</template>

<script>
import { request, exportForm, requestForm } from '@/assets/js/http.js'
import { baseHost, tokenHeader } from '@/assets/js/PublicData.js'
import { MIME } from '@/assets/js/mime.js'
import {
  SupplierRegistrFormData,
  supplierAddressList,
  supplierContactList,
  srmFileList,
  SupplierRegistrFormShow,
  supplyMaterialTypeData
} from './js/SupplierConfig.js'
import AreaSelector from '@/components/plug/AreaSelector'

export default {
  name: 'CompanyRegistration',
  components: { AreaSelector },
  data () {
    return {
      formData: SupplierRegistrFormData,
      tokenHeader: tokenHeader,
      participantOptions: [],
      actionURL: `${baseHost.SupplierRegHost}/api/file/m/file/uploadToServer`,
      businessScopeData: [],
      supplyMaterialTypeData: supplyMaterialTypeData,
      supplierAddressList: supplierAddressList,
      supplierContactList: supplierContactList,
      srmFileList: srmFileList,
      FormAttr: SupplierRegistrFormShow,
      formName: '补充公司信息',
      uploadKey: 0,
      canChange: true,
      canSave: false,
      canSubmit: false,
      canReject: false,
      haveInfo: false,
      canback: true,
      tempBuyerName: {}
    }
  },
  watch: {
    $route: {
      handler () {
        this.initialize()
      }
    }
  },
  created () {
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.businessScopeData = response
    })
    requestForm('/api/system/role/getUserByRoleId', 'post', { roleId: '540776582185422848' }).then((response) => {
      if (response.code === '200') {
        this.participantOptions = response.data
      }
    })
    if (this.$store.state.loginInfo.userType === 1) {
      request('/api/supplier/basicData/list', 'get', { pageNum: 1, pageSize: 999, createUserId: this.$store.state.loginInfo.id }).then((response) => {
        if (response.code === '200') {
          if (response.data.records.length > 0) {
            this.$router.push({ query: { id: response.data.records[0].id } })
            this.initialize()
          }
        }
      })
    }
    this.initialize()
  },
  methods: {
    initialize () {
      var module = this.$route.params.viewName
      if (module !== 'CompanyMessage') {
        return false
      }
      request('/api/supplier/basicData/companyMessage', 'get').then((response) => {
        if (response.code === '200') {
          this.formData = response.data
          this.formName = response.data.name
        }
      })
    },
    clickKey (key) {
      this.uploadKey = key
    },
    back: () => {
      window.history.back()
    },
    addlist (list, addData) {
      var data = JSON.parse(JSON.stringify(addData))
      list.push(data)
    },
    dellist (list, key) {
      if (list.length > 1) {
        list.splice(key, 1)
      }
    },
    changAction (data) {},
    change () {
      this.$router.push({ params: { viewName: 'SupplierRegistration' }, query: { id: this.formData.id, type: 'edit' } })
    },
    handleRemove (file) {
      for (var i = 0; i < this.formData.fileVoList[this.uploadKey].srmFileList.length; i++) {
        if (this.formData.fileVoList[this.uploadKey].srmFileList[i].id === file.id) {
          this.formData.fileVoList[this.uploadKey].srmFileList.splice(i, 1)
        }
      }
    },
    handlePreview (file) {
      exportForm(`/api/file/m/file/downloadFile/${file.id}`, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.formData.fileVoList[this.uploadKey].srmFileList.push(element)
        })
      }
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
</style>
